import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { AssessmentS3DataUrl } from 'src/app/shared/enums/AssessmentS3DataUrl';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { FrameworkStatus } from 'src/app/shared/enums/FrameworkStatus';
import { IconType } from 'src/app/shared/enums/IconType';
import { MetStatuses } from 'src/app/shared/enums/MetStatuses';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IWysiwygEditorInsertOperation } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IScoringAssessmentModal } from 'src/app/shared/interfaces/modal/IScoringAssessmentModal';
import { IScoringAssessmentDiscreetOptionsModal } from 'src/app/shared/interfaces/modal/IScoringAssessmentDiscreetOptionsModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IWidgetData } from 'src/app/shared/interfaces/widget/IWidgetData';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { SubSink } from 'subsink';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { ApiUrls } from './../../../../../core/classes/ApiUrls';
import {
    AssessmentAuditTypes,
    AssessmentCacheService
} from './../../../../services/cache/assessment-cache/assessment-cache.service';
import { GlobalDataService } from './../../../../services/global-data/global-data.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { ScoringAssessmentModalComponent } from '../../scoring-assessment-modal/scoring-assessment-modal.component';
import { ScoringAssessmentDiscreetOptionsModalComponent } from '../../scoring-assessment-discreet-options-modal/scoring-assessment-discreet-options-modal.component';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { AuditEvidenceSaveConfirmationService } from 'src/app/shared/services/assessment/audit-evidence-save-confirmation.service';
import { AssessmentConfiguration } from 'src/app/core/classes/AssessmentConfiguration';

enum RemarkType {
    PUBLIC = 'Public',
    PRIVATE = 'Private'
}
@Component({
    selector: 'app-generate-framework',
    templateUrl: './generate-framework.component.html',
    styleUrls: ['./generate-framework.component.sass']
})
export class GenerateFrameworkComponent
    implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
    tabIndex = {
        [Tab.TECHNICAL_DESCRIPTION]: 0,
        [Tab.UPLOAD_EVIDENCE]: 1,
        [Tab.TECHNICAL_ASSESSMENT]: 2
    };
    @Input() frameworkData: any;
    summaryFormGenInputs: IFormGeneratorInput;
    summaryFormGroup: FormGroup;
    showMenu: boolean;
    requiredEvidenceFormGenInputs: IFormGeneratorInput;
    requiredEvidenceFormGroup: FormGroup;
    uploadEvidenceFormGenInputs: IFormGeneratorInput;
    uploadEvidenceFormGroup: FormGroup;
    Tab = Tab;
    CommentType = CommentType;
    currentPage: number = 1;
    widgetSubs = new SubSink();
    private subs = new SubSink();
    private formSubscriptions = new SubSink();
    widgetIds: string[] = [];
    widgetData: Map<string, IWidgetData> = new Map<string, IWidgetData>();
    paginationData: Map<number, string[]> = new Map<number, string[]>();
    loadingData = new BehaviorSubject(true);
    eCommentType = RemarkType;
    commentType: string = this.eCommentType.PUBLIC;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    deleteIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'delete_icon',
        extraClass: 'action-icon'
    };
    downloadIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'download_icon',
        extraClass: 'action-icon'
    };
    editIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'edit_icon',
        extraClass: 'action-icon'
    };
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross_icon',
        extraClass: 'svg-accent-fill'
    };
    openedEditor: 'comment' | 'reply' | 'addEvidence' = null;
    visibleEditorId: string = null;
    file: any;
    fileName: string;
    SanitizeTypes = SanitizeTypes;
    assessmentData;
    comments: any;
    commentId: string;
    frameworkStatus = FrameworkStatus;
    showReply: Map<string, boolean> = new Map();
    editCommentData: any;
    confirmationModalId: Symbol;
    loader: boolean = false;
    uploadingDocument: boolean = false;
    disableCross: boolean = false;
    uploadingComment: boolean = false;
    restrictActionForStatus: string[] = [
        this.frameworkStatus.ISSUED,
        this.frameworkStatus.IN_PROGRESS
    ];
    statusLoader: Map<string, boolean> = new Map();
    fileIconDisable: Map<string, boolean> = new Map();
    assesseeSubmitted: boolean = false;
    assessorSubmitted: boolean = false;
    @ViewChild('tabGroup', { static: false }) tabGroup;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    assesseeButtonGenInput: IButtonGeneratorInput[];
    assessorButtonGenInput: IButtonGeneratorInput[];
    assessorButtonOption: IMultiButtonOption;
    assesseeButtonOption: IMultiButtonOption;
    attachDocButton: IButtonGeneratorInput;
    submitEvidenceButton: IButtonGeneratorInput;
    cancelEvidenceButton: IButtonGeneratorInput;
    disableAssessorButtons: boolean = false;
    checkDropdownButtons: IFilterInfo;
    selectedValues: string[];
    checkButton: IButtonGeneratorInput;
    metButtonInputs: IButtonGeneratorInput[];
    @ViewChild('checkMenu') checkMenu: MatMenu;
    @ViewChildren('commentEditor')
    private editorQueryList: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
    @ViewChild('inp') fileInput: ElementRef;
    dropdownIcon: IIcon = {
        type: IconType.SVG,
        class: 'circular_edit'
    };
    selectedTab;
    isPublic: boolean;
    activeTabIndex = 0;
    tabSwitched = new BehaviorSubject(false);
    commentsLoaded: string = 'commentsNotLoaded';
    commentsMap = {};
    updatedComment;
    private maxScore: number = 7; // default value to be removed later
    private scoringDiscreetOptions: Array<string | number> = [];
    private isJuniperAudit = this.assessmentCacheService.assessmentAuditType ===
        AssessmentAuditTypes.JUNIPER_AUDIT
    infoMessage: string;
    constructor(
        public globalDataService: GlobalDataService,
        private widgetHttpService: WidgetHttpService,
        private filtersService: FiltersService,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private cdRef: ChangeDetectorRef,
        public assessmentCacheService: AssessmentCacheService,
        private auditEvidenceSaveConfirmationService: AuditEvidenceSaveConfirmationService,
        public userDataCacheService: UserDataCacheService,
    ) {
        if (this.frameworkData && this.globalDataService.frameworkData.getValue()) {
            // store framework data when we refresh while on a control point
            this.globalDataService.frameworkData.next(this.frameworkData);
        }
        this.globalDataService.frameworkData.subscribe((data) => {
            if (data && this.frameworkData) {
                this.frameworkData = data;
                this.comments = null;
                this.globalDataService.isFrameworkPage.next(false);

                if (this.confirmationModalId) {
                    this.modalService.closeModal(
                        null,
                        this.confirmationModalId
                    );

                    this.confirmationModalId = null;
                    this.cdRef.detectChanges();
                }
                if (this.loader) {
                    this.loader = false;
                    this.cdRef.detectChanges();
                }
                if (this.isJuniperAudit) {
                    if (this.frameworkData['disabled'])
                        this.infoMessage = this.globalDataService.getTooltipMessage(this.frameworkData);
                    else
                        this.infoMessage = '';
                }
            }
        });
        this.assessmentData = this.assessmentCacheService.assessmentData;
        this.globalDataService.pageSwitch.subscribe((data) => {
            if (data.switched) {
                this.globalDataService.lastActiveAssessmentTab = null;
            }
        });
    }

    ngOnInit(): void {
        this.subs.sink = this.auditEvidenceSaveConfirmationService
            .getIsNavigationTriggeredWithoutSave()
            .subscribe((s) => {
                if (s) {
                    this.auditEvidenceSaveConfirmationService.navigateAwayConfirmationModal(
                        (modalId, formGroup, buttonRef) => {
                            this.getKeyFromBackend(
                                buttonRef,
                                this.commentId ? this.commentId : null,
                                (isNavigatingAway: boolean = false) => {
                                    this.modalService.closeModal(null, modalId);
                                    if (isNavigatingAway) {
                                        this.auditEvidenceSaveConfirmationService.continuePausedNavigation();
                                    }
                                }
                            );
                        },
                        () => {
                            this.auditEvidenceSaveConfirmationService.continuePausedNavigation();
                        }
                    );
                }
            });

        this.paginationData.clear();
        this.selectedTab =
            this.globalDataService.lastActiveAssessmentTab ??
            Tab.TECHNICAL_DESCRIPTION;
        if (!Object.keys(this.frameworkData['framework']).length) {
            const apiArgs: IHitApi = {
                url: ApiUrls.CREATE_FRAMEWORK,
                input: {},
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (res) => {
                    this.frameworkData['framework']['id'] = res.id;
                    this.getTechnicalDescription(
                        this.frameworkData['framework'].id
                    );
                    this.getWidgetIds(this.frameworkData['framework'].id);
                    this.getStatus(this.frameworkData['framework'].id);
                    this.getCommentUrls(this.frameworkData['framework'].id);
                    this.getTags(this.frameworkData['framework'].id);
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.fileIconDisable.clear();
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.url = apiArgs.url.replace(
                '{assessment-id}',
                this.assessmentCacheService.assessmentId
            );
            apiArgs.url = apiArgs.url.replace(
                '{attribute-id}',
                this.frameworkData['id']
            );
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        }
        // getting technical description
        if (this.frameworkData['framework'].id) {
            this.getTechnicalDescription(this.frameworkData['framework'].id);
            // getting status of framework
            this.getWidgetIds(this.frameworkData['framework'].id);
            this.getStatus(this.frameworkData['framework'].id);
            this.getCommentUrls(this.frameworkData['framework'].id);
            this.getTags(this.frameworkData['framework'].id);
        }
        //below conditions: if we are getting direct url's for summary and evidence and not framework id works for viewing assessment
        if (this.frameworkData['framework']['summary']) {
            this.getSummary(this.frameworkData['framework']['summary']);
        }
        if (this.frameworkData['framework']['requiredEvidence']) {
            this.getRequiredEvidence(
                this.frameworkData['framework']['requiredEvidence']
            );
        }
        if (this.frameworkData['framework']['status']) {
            this.frameworkData['framework']['status'] =
                this.frameworkData['framework']['status'];
            this.frameworkData['framework']['colorCode'] =
                this.frameworkData['framework']['colorCode'];
            this.notificationService.updateControlPointStatus.next({
                statusName: this.frameworkData['framework']['status'],
                statusColor: this.frameworkData['framework']['colorCode']
            });
        }
        if (this.frameworkData['assesseeSubmitted']) {
            this.assesseeSubmitted = this.frameworkData['assesseeSubmitted'];
        }
        if (this.frameworkData['assessorSubmitted']) {
            this.assessorSubmitted = this.frameworkData['assessorSubmitted'];
        }
        if (this.isCSPScoring && this.frameworkData?.['maxScore']) {
            this.maxScore = this.frameworkData?.['maxScore'];
        }
        if (this.isMsSupportScoring && this.frameworkData?.['eligibleScore']) {
            this.scoringDiscreetOptions = this.frameworkData?.['eligibleScore'];
        }
        this.generateUploadEvidenceForm();
        if (
            this.frameworkData &&
            this.frameworkData['framework'] &&
            this.frameworkData['framework']['widgets']
        ) {
            this.widgetIds = this.frameworkData['framework']['widgets'].map(
                (widgetObj) => widgetObj['id']
            );
        }
        if (this.widgetIds && this.widgetIds.length) {
            this.setPagination();
            this.generateWidgets();
        } else {
            this.loadingData.next(false);
        }
        if (
            this.assessmentCacheService.assessmentStatus &&
            this.restrictActionForStatus.includes(
                this.assessmentCacheService.assessmentStatus
            ) || (this.isJuniperAudit && this.frameworkData['disabled'])
        ) {
            this.disableAssessorButtons = true;
        }
        this.globalDataService.sideNavToggle.subscribe((data) => {
            if (data) {
                if (this.tabGroup) {
                    this.tabGroup.realignInkBar();
                }
            }
        });
        this.assesseeButtonGenInput = [
            {
                buttonName: 'Marked For Review',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.STROKED,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.MARKED_FOR_REVIEW,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'Complete',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.READY_FOR_ASSESSMENT,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : 'opacity-1'
            }
        ];
        const genericAssessorButtonGenInput: IButtonGeneratorInput[] = [
            {
                buttonName: 'Not Met',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.STROKED,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkData['icon'].startsWith('3') &&
                            this.isJuniperAudit && this.frameworkData['framework'].status === 'Not Met'
                            ? this.frameworkStatus.INCOMPLETE
                            : this.frameworkStatus.REJECTED,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled']) 
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'Open Action Item',
                buttonColorType: ButtonColorType.INFO,
                buttonType: ButtonType.STROKED,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.MARKED_FOR_REWORK,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled']) 
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'Met',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    if (
                        this.assessmentCacheService.assessmentAuditType === 'Hp'
                    ) {
                        this.menuTrigger.openMenu();
                    } else {
                        this.changeStatusOfFramework(
                            this.frameworkData['icon'].startsWith('3') &&
                                this.isJuniperAudit && this.frameworkData['framework'].status === 'Met'
                                ? this.frameworkStatus.INCOMPLETE
                                : this.frameworkStatus.APPROVED,
                            buttonRef
                        );
                    }
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            }
        ];
        const AssessorButtonGenInput_CSP: IButtonGeneratorInput[] = [
            {
                buttonName: 'Open Action Item',
                buttonColorType: ButtonColorType.INFO,
                buttonType: ButtonType.STROKED,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.MARKED_FOR_REWORK,
                        buttonRef
                    );
                },
                showLoader: true,
                hide:
                    this.isMsSupportScoring &&
                    AssessmentConfiguration.hideScoreInAttributes.includes(
                        this.frameworkData?.['attributeName']?.toLowerCase()
                    ),
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'Edit Score',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        'change-score',
                        buttonRef,
                        null,
                        true
                    );
                },
                showLoader: true,
                hide:
                    this.isMsSupportScoring &&
                    AssessmentConfiguration.hideScoreInAttributes.includes(
                        this.frameworkData?.['attributeName']?.toLowerCase()
                    ),
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'Submit',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            }
        ];
        const AssessorButtonGenInput_GOOGLE_MSP: IButtonGeneratorInput[] = [
            {
                buttonName: 'Submit',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef
                    );
                },
                showLoader: true,
                disable: this.assessmentCacheService.assessmentView
                    ? true
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : this.disableAssessorButtons
                    ? true
                    : false,
                customClass: this.assessmentCacheService.assessmentView
                    ? 'decrease-opacity'
                    : this.assesseeSubmitted &&
                      !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.disableAssessorButtons
                    ? 'decrease-opacity'
                    : 'opacity-1'
            }
        ];
        if (this.isCSPScoring || this.isMsSupportScoring) {
            this.assessorButtonGenInput = AssessorButtonGenInput_CSP;
        } else if (
            this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.GOOGLE_MSP
        ) {
            this.assessorButtonGenInput = AssessorButtonGenInput_GOOGLE_MSP;
        } else {
            this.assessorButtonGenInput = genericAssessorButtonGenInput;
        }
        this.assessorButtonOption = {
            disable: this.assessmentCacheService.assessmentView
                ? true
                : this.assessmentData &&
                  !!this.assessmentData.response['assesseOverview']
                ? false
                : this.assesseeSubmitted &&
                  !this.userDataCacheService.isAssessorView
                ? true
                : this.assessorSubmitted &&
                  this.userDataCacheService.isAssessorView
                ? true
                : this.disableAssessorButtons
                ? true
                : (this.isJuniperAudit && this.frameworkData['disabled'])
                ? true
                : false
        };
        this.assesseeButtonOption = {
            disable: this.assessmentCacheService.assessmentView
                ? true
                : this.assessmentData &&
                  !!this.assessmentData.response['assesseOverview']
                ? false
                : this.assesseeSubmitted &&
                  !this.userDataCacheService.isAssessorView
                ? true
                : this.assessorSubmitted &&
                  this.userDataCacheService.isAssessorView
                ? true
                : false
        };
        this.checkButton = {
            buttonName: 'Remark view',
            buttonIcon: this.dropdownIcon,
            function: (buttonRef: IButtonGeneratorInput) => {},
            buttonColorType: ButtonColorType.INFO,
            buttonType: ButtonType.TEXT,
            showLoader: true
        };
        this.metButtonInputs = [
            {
                buttonName: 'Not Applicable',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.NOT_APPLICABLE
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Exempted',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.EXEMPTED
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Ad Hoc',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.AD_HOC
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Repeatable',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.REPEATABLE
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Defined',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.DEFINED
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Optimised',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.OPTIMIZED
                    );
                },
                showLoader: true
            },
            {
                buttonName: 'Innovative',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.changeStatusOfFramework(
                        this.frameworkStatus.APPROVED,
                        buttonRef,
                        MetStatuses.INNOVATIVE
                    );
                },
                showLoader: true
            }
        ];
        this.generateUploadEvidenceButtons();
        this.checkDropdownButtons = {
            placeholder: '',
            listData: [
                {
                    id: 'private',
                    label: 'Private'
                },
                {
                    id: 'public',
                    label: 'Public'
                }
            ]
        };
        this.selectedValues = ['private'];
        this.globalDataService.assessmentStatus.next(
            this.assessmentData?.response.status
        );
    }

    ngAfterViewInit(): void {
        this.subs.sink = this.editorQueryList.changes.subscribe(() => {
            this.formSubscriptions.unsubscribe();
            const el = this.editorQueryList?.first;
            if (el) {
                this.formSubscriptions.sink = this.uploadEvidenceFormGroup
                    ?.get('evidence')
                    ?.valueChanges?.subscribe(
                        this.evidenceFormControlValueChangeCallback.bind(this)
                    );
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.formSubscriptions.unsubscribe();
        this.auditEvidenceSaveConfirmationService.setIsNavigationTriggeredWithoutSave(
            false
        );
        this.auditEvidenceSaveConfirmationService.setIsUnsavedDataPresent(
            false
        );
    }

    private evidenceFormControlValueChangeCallback(newValue) {
        if (
            newValue &&
            !this.auditEvidenceSaveConfirmationService.getIsUnsavedDataPresent()
        ) {
            this.auditEvidenceSaveConfirmationService.setIsUnsavedDataPresent(
                true
            );
        } else if (newValue === "") {
            this.auditEvidenceSaveConfirmationService.setIsUnsavedDataPresent(
                false
            );
        }
    }

    public setUploadEvidenceFormGroup(formGroup: FormGroup) {
        if (formGroup !== this.uploadEvidenceFormGroup) {
            if (this.uploadEvidenceFormGroup && this.tabSwitched.getValue()) {
                this.tabSwitched.next(false);
                formGroup.patchValue(this.uploadEvidenceFormGroup.value, {
                    emitEvent: false
                });
                this.evidenceFormControlValueChangeCallback(
                    this.uploadEvidenceFormGroup.value?.evidence
                );
            }
            this.uploadEvidenceFormGroup = formGroup;
        }
    }

    //getting technical description urls and hitting apis of s3 and showing data: completed
    getTechnicalDescription(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_TECHNICAL_DESCRIPTION,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                if (res.summary) {
                    this.getSummary(res.summary);
                } else if (res && !Object.keys(res).length) {
                    this.generateTechnicalDescriptionSection();
                }
                if (res.requiredEvidence) {
                    this.getRequiredEvidence(res.requiredEvidence);
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        apiArgs.url = apiArgs.url.replace('{framework-id}', id);
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getSummary(summary) {
        const apiArgs: IHitApi = {
            url: summary,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true,
                defaultHeaders: {
                    'Content-Type': 'text/plain'
                },
                responseType: 'text'
            },
            function: (res) => {
                this.frameworkData['framework']['summary'] = decodeURIComponent(
                    window.escape(atob(res.split(',')[1]))
                );
                this.generateTechnicalDescriptionSection();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getRequiredEvidence(requiredEvidence) {
        const apiArgs: IHitApi = {
            url: requiredEvidence,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true,
                defaultHeaders: {
                    'Content-Type': 'text/plain'
                },
                responseType: 'text'
            },
            function: (res) => {
                this.frameworkData['framework']['requiredEvidence'] =
                    decodeURIComponent(window.escape(atob(res.split(',')[1])));
                this.generateTechnicalDescriptionSection();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getStatus(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_STATUS,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                if (this.userDataCacheService.isAssessorView) {
                    this.frameworkData['framework']['status'] =
                        res.assessorAction;
                    this.frameworkData['framework']['colorCode'] =
                        res.assessorActionColorCode;
                    this.notificationService.updateControlPointStatus.next({
                        statusName: res.assessorAction,
                        statusColor: res.assessorActionColorCode,
                        score: res?.score
                    });
                } else if (!this.userDataCacheService.isAssessorView) {
                    this.frameworkData['framework']['status'] =
                        res.assesseeAction;
                    this.frameworkData['framework']['colorCode'] =
                        res.assesseeActionColorCode;
                    this.notificationService.updateControlPointStatus.next({
                        statusName: res.assesseeAction,
                        statusColor: res.assesseeActionColorCode,
                        score: res?.score
                    });
                }
                if (
                    this.frameworkData['framework']['status'] ===
                        'Open Action Item' ||
                    res.assessorAction === 'Marked For Rework'
                ) {
                    this.assessorButtonGenInput =
                        this.assessorButtonGenInput.map((button) => {
                            button.disable = true;
                            return button;
                        });
                }
                this.cdRef.detectChanges();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.notificationService,
                    error.message
                );
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        apiArgs.url = apiArgs.url.replace('{framework-id}', id);
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getCommentUrls(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_COMMENT_URL,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.getComments(res);
                this.checkCommentsCollected(res);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
            }
        };
        apiArgs.url = apiArgs.url.replace('{framework-id}', id);
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getComments(comment) {
        if (comment.length) {
            this.commentsLoaded = 'commentsNotLoaded';
            for (let i = 0; i < comment.length; i++) {
                this.commentsMap[comment[i].commentId] = false;
                if (comment[i].comment !== '') {
                    const apiArgs: IHitApi = {
                        url: comment[i].comment,
                        input: {},
                        requestType: RequestType.GET,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED,
                            ignoreBaseUrl: true,
                            defaultHeaders: {
                                'Content-Type': 'text/plain'
                            },
                            responseType: 'text'
                        },
                        function: (res) => {
                            comment[i].comment = res
                                ? decodeURIComponent(
                                      window.escape(atob(res.split(',')[1]))
                                  )
                                : '';
                            this.commentsMap[comment[i].commentId] = true;
                            this.checkCommentsCollected();
                            this.visibleEditorId = null;
                            this.cdRef.detectChanges();
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error
                            );
                            this.fileIconDisable.clear();
                            this.cdRef.detectChanges();
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetHttpService,
                        this.widgetHttpService.ngZone
                    ).hitApi();
                } else if (
                    comment[i].comment === '' &&
                    comment[i].file !== ''
                ) {
                    this.commentsMap[comment[i].commentId] = true;
                    this.checkCommentsCollected();
                }
                if (comment[i].comments) {
                    this.getComments(comment[i].comments);
                    this.cdRef.detectChanges();
                }
            }
        } else {
            this.frameworkData['framework']['comments'] = [];
            this.commentsLoaded = 'noComments';
            this.comments = [];
        }
    }
    checkCommentsCollected(comment?) {
        if (comment) {
            this.updatedComment = comment;
        }
        const value = Object.keys(this.commentsMap).filter(
            (id) => this.commentsMap[id] === false
        );
        if (!value.length) {
            this.frameworkData['framework']['comments'] = this.updatedComment;
            this.comments = this.updatedComment;
            if (this.updatedComment?.length) {
                this.commentsLoaded = 'commentsLoaded';
            } else {
                this.commentsLoaded = 'noComments';
            }
            this.cdRef.detectChanges();
        }
    }
    getWidgetIds(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_WIDGETS_FRAMEWORK,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.widgetIds = res.map((widgetObj) => widgetObj['id']);
                if (this.widgetIds && this.widgetIds.length) {
                    this.setPagination();
                    this.generateWidgets();
                } else {
                    this.loadingData.next(false);
                }
                if (
                    this.globalDataService.lastActiveAssessmentTab ===
                        Tab.TECHNICAL_ASSESSMENT &&
                    !this.widgetIds.length
                ) {
                    this.selectedTab = Tab.TECHNICAL_DESCRIPTION;
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        apiArgs.url = apiArgs.url.replace('{framework-id}', id);
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getTags(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_TAGS_FRAMEWORK,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.frameworkData['framework']['tags'] = res;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        apiArgs.url = apiArgs.url.replace('{framework-id}', id);
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    ngAfterViewChecked() {
        if (this.checkButton && this.checkMenu) {
            this.checkButton.matMenu = this.checkMenu;
        }
    }
    generateUploadEvidenceButtons() {
        this.attachDocButton = {
            buttonName: 'Attach Document',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.STROKED,
            function: (buttonRef) => {
                this.fileReader();
            },
            showLoader: true,
            disable:
                this.assesseeSubmitted &&
                !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? true
                    : false,
            customClass:
                this.assesseeSubmitted &&
                !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? 'decrease-opacity'
                    : 'opacity-1'
        };
        this.submitEvidenceButton = {
            buttonName: 'Submit',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            disable:
                this.assesseeSubmitted &&
                !this.userDataCacheService.isAssessorView
                    ? true
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? true
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? true
                    : false,
            customClass:
                this.assesseeSubmitted &&
                !this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? 'decrease-opacity'
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? 'decrease-opacity'
                    : 'opacity-1',
            loader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.getKeyFromBackend(
                    buttonRef,
                    this.commentId ? this.commentId : null
                );
            }
        };
        this.cancelEvidenceButton = {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.WARN,
            buttonType: ButtonType.STROKED,
            function: (buttonRef) => {
                this.toggle();
            }
        };
    }
    tabChanged(tab) {
        this.tabSwitched.next(true);
        this.selectedTab = tab;
        this.globalDataService.lastActiveAssessmentTab = tab;
        if (tab === this.Tab.TECHNICAL_ASSESSMENT) {
            if (this.widgetIds && this.widgetIds.length) {
                this.globalDataService.isFrameworkPage.next(true);
            }
        } else {
            this.globalDataService.isFrameworkPage.next(false);
        }
    }
    setPagination() {
        let pageNum = 1;
        this.widgetIds.forEach((ids) => {
            if (this.paginationData.has(pageNum)) {
                if (this.paginationData.get(pageNum).length <= 9) {
                    this.paginationData.get(pageNum).push(ids);
                } else {
                    pageNum = pageNum + 1;
                    this.paginationData.set(pageNum, [ids]);
                }
            } else {
                this.paginationData.set(pageNum, [ids]);
            }
        });
    }
    toggle(comment?) {
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            this.openedEditor = null;
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        this.fileName = null;
        if (this.openedEditor === 'addEvidence') {
            this.editCommentData = null;
        }
        if (this.editCommentData) {
            this.generateUploadEvidenceForm(comment);
            this.fileName = this.editCommentData['fileName'];
        }
    }
    removeFile() {
        this.fileName = null;
        this.file = null;
        this.fileInput.nativeElement.value = null;
    }
    download(file, commentId) {
        if (!file) {
            return;
        }
        if (this.assesseeSubmitted && this.assessorSubmitted) {
            return;
        }
        if (this.fileIconDisable.get(commentId)) {
            return;
        }
        this.fileIconDisable.set(commentId, true);
        const apiArgs: IHitApi = {
            url: file,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true,
                sendOnlyResponse: true
            },
            function: null,
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        )
            .hitApi()
            .subscribe((data) => {
                Helper.convertBase64ToFile(data['file'], data['fileName']);
                this.fileIconDisable.clear();
                this.cdRef.detectChanges();
            });
    }
    generateTechnicalDescriptionSection() {
        this.summaryFormGenInputs = {
            formName: 'Summary',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.CONTENT,
                    label: '',
                    name: 'summary',
                    placeholder: 'Summary',
                    required: false,
                    showTooltip: false,
                    value: this.frameworkData['framework']['summary'] ?? ''
                }
            ]
        };
        this.requiredEvidenceFormGenInputs = {
            formName: 'Required Evidence',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.CONTENT,
                    label: '',
                    name: 'evidence',
                    placeholder: 'Evidence',
                    required: false,
                    showTooltip: false,
                    value:
                        this.frameworkData['framework']['requiredEvidence'] ??
                        ''
                }
            ]
        };
        this.cdRef.detectChanges();
        this.generateUploadEvidenceForm();
    }
    generateUploadEvidenceForm(value?) {
        this.uploadEvidenceFormGenInputs = {
            formName: 'Upload Evidence',
            state:
                this.assesseeSubmitted &&
                !this.userDataCacheService.isAssessorView
                    ? FormState.IDLE
                    : this.assessorSubmitted &&
                      this.userDataCacheService.isAssessorView
                    ? FormState.IDLE
                    : (this.isJuniperAudit && this.frameworkData['disabled'])
                    ? FormState.IDLE
                    : FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    appearance: WysiwygEditorField.TYPE_3,
                    label: '',
                    name: 'evidence',
                    placeholder: '',
                    required: false,
                    hideWysiwygEditorInsertOperation: [
                        IWysiwygEditorInsertOperation.PICTURE
                    ],
                    value: value ? value : '',
                    extraClass:
                        this.assesseeSubmitted &&
                        !this.userDataCacheService.isAssessorView
                            ? 'editor pointer-null'
                            : this.assessorSubmitted &&
                              this.userDataCacheService.isAssessorView
                            ? 'editor pointer-null'
                            : (this.isJuniperAudit && this.frameworkData['disabled'])
                            ? 'editor pointer-null'
                            : 'note-editor',
                    characterLimit: 10000,
                    disableFontChange: true,
                    customInputCallback: (
                        totalCharacterCount,
                        leftOverCharacterCount
                    ) => {
                        const button: IButtonGeneratorInput = Helper.cloneDeep(
                            this.submitEvidenceButton
                        );

                        if (
                            leftOverCharacterCount >= 0 &&
                            ((button && !button.disable) ||
                                (button && !button.showLoader))
                        ) {
                            if (button) {
                                button.disable = false;
                                button.showLoader = false;
                            }
                        } else {
                            if (button) button.disable = true;
                        }
                        this.submitEvidenceButton = button;
                    }
                }
            ]
        };
        this.cdRef.detectChanges();
    }
    generateWidgets() {
        this.loadingData.next(true);
        this.filtersService.filtersPageData.next(null);
        this.widgetData = new Map<string, IWidgetData>();
        this.widgetSubs.unsubscribe();
        this.paginationData.get(+this.currentPage).forEach((widgetId) => {
            this.widgetHttpService.loadWidgetsData(
                this.widgetHttpService,
                this.widgetSubs,
                widgetId,
                this.widgetData,
                this.checkIfAllDataCollected.bind(this)
            );
        });
    }
    editorClose(onClick) {
        if (onClick) {
            this.uploadEvidenceFormGroup = null;
            this.formSubscriptions.unsubscribe();
            this.auditEvidenceSaveConfirmationService.setIsUnsavedDataPresent(
                false
            );
            this.tabSwitched.next(false);
            this.assessorButtonGenInput?.forEach((button) => {
                button.disable = false;
            });
            this.assesseeButtonGenInput?.forEach((button) => {
                button.disable = false;
            });
        }
    }
    checkIfAllDataCollected() {
        if (
            this.paginationData.get(+this.currentPage)?.length ===
            this.widgetData.size
        ) {
            // All widgets data collected
            this.filtersService.generateFiltersData(
                FilterStoreKey.WEBSITE_FILTERS,
                this.widgetData,
                this.paginationData.get(this.currentPage)
            );
            this.loadingData.next(false);
            this.cdRef.detectChanges();
        }
    }
    widgetIdentify(index, widgetId) {
        return widgetId;
    }
    changePage(pageNumber, field?: HTMLInputElement) {
        if (pageNumber < 0 || pageNumber > this.paginationData.size) {
            if (field) {
                field.value = `${this.currentPage}`;
            }
        } else {
            this.currentPage = pageNumber;
            this.generateWidgets();
        }
    }
    fileReader() {
        const elem = document.getElementById('file-reader');
        if (elem && document.createEvent) {
            const evnt = document.createEvent('MouseEvents');
            evnt.initEvent('click', true, false);
            elem.dispatchEvent(evnt);
        }
    }
    fileSelected(event) {
        this.disableCross = false;
        this.fileName = event.target.files
            ? event.target.files[0]['name']
            : null;
        Helper.convertImageToBase64(event.target)
            .then((data) => {
                this.file = data;
            })
            .catch((error) => {
                Helper.showErrorMessage(this.notificationService, error);
            });
    }
    addReplyToComment(commentId, visibleEditorId) {
        this.assessorButtonGenInput.forEach((button) => {
            button.disable = true;
        });
        this.assesseeButtonGenInput.forEach((button) => {
            button.disable = true;
        });
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        this.visibleEditorId = visibleEditorId;
        this.editCommentData = null;
        this.toggle();
        this.commentId = commentId;
        this.cdRef.detectChanges();
    }
    submit(
        buttonRef: IButtonGeneratorInput,
        inputs,
        onFlowCompleted?: Function
    ) {
        if (this.commentType === 'Private') {
            this.isPublic = false;
            inputs['public'] = false;
        } else {
            this.isPublic = true;
            inputs['public'] = true;
        }
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        this.loader = true;
        const apiArgs: IHitApi = {
            url: this.editCommentData
                ? ApiUrls.EDIT_COMMENT
                : ApiUrls.ADD_COMMENT,
            input: inputs,
            requestType: this.editCommentData
                ? RequestType.PATCH
                : RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: () => {
                this.editorClose(true);
                this.generateUploadEvidenceForm();
                buttonRef.showLoader = false;
                buttonRef.disable = false;
                this.submitEvidenceButton.disable = false;
                this.submitEvidenceButton.showLoader = false;
                this.editCommentData = null;
                this.commentId = null;
                this.attachDocButton.disable = false;
                this.uploadingDocument = false;
                this.openedEditor = null;
                this.file = null;
                this.fileName = null;
                this.getCommentUrls(this.frameworkData['framework'].id);
                this.toggle();
                typeof onFlowCompleted === 'function' && onFlowCompleted(true);
                this.cdRef.detectChanges();
            },
            errorFunction: (error) => {
                buttonRef.showLoader = false;
                buttonRef.disable = false;
                Helper.showErrorMessage(this.notificationService, error);
                this.editCommentData = null;
                this.commentId = null;
                this.toggle();
                this.file = null;
                this.fileName = null;
                this.loader = false;
                this.uploadingDocument = false;
                this.attachDocButton.disable = false;
                typeof onFlowCompleted === 'function' && onFlowCompleted();
                this.cdRef.detectChanges();
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.input = {
            ...apiArgs.input,
            frameworkIds: [this.frameworkData['framework'].id]
        };
        if (this.editCommentData) {
            apiArgs.url = apiArgs.url + '/' + this.editCommentData['commentId'];
            apiArgs.url.replace('framework', 'attribute');
            apiArgs.input = {
                ...apiArgs.input,
                attributeIds: ['aa']
            };
        }

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getKeyFromBackend(
        buttonRef: IButtonGeneratorInput,
        commentId?,
        onFlowCompleted?: Function
    ) {
        buttonRef.showLoader = true;
        this.attachDocButton.disable = true;
        buttonRef.disable = true;
        this.disableCross = true;
        const inputs = commentId
            ? this.generateInput(commentId)
            : this.generateInput();
        if (inputs.comment !== '') {
            const apiArgs: IHitApi = {
                url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.COMMENTS,
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (res) => {
                    this.dumpDataToS3(
                        res,
                        commentId,
                        buttonRef,
                        onFlowCompleted
                    );
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.editCommentData = null;
                    this.commentId = null;
                    this.file = null;
                    this.fileName = null;
                    this.loader = false;
                    buttonRef.showLoader = false;
                    buttonRef.disable = false;
                    this.uploadingDocument = false;
                    typeof onFlowCompleted === 'function' &&
                        onFlowCompleted(false);
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        } else {
            this.uploadDocument(buttonRef, inputs);
        }
    }
    dumpDataToS3(response, commentId, buttonRef, onFlowCompleted?: Function) {
        const inputs = commentId
            ? this.generateInput(commentId)
            : this.generateInput();
        let comment = Helper.trimHTML(
            Helper.stringToHTML(inputs.comment)
        ).innerHTML?.toString();
        // Moving Closing Brackets out of the Anchor tag.
        const commentHtml = Helper.stringToHTML(inputs.comment);
        // Removing closing brackets from link.
        for (let i = 0; i < commentHtml.getElementsByTagName('a').length; i++) {
            const anchorTagAttribute =
                commentHtml.getElementsByTagName('a')[i]?.href;
            let UpdatedAnchorTag = anchorTagAttribute?.replace(')', '');
            UpdatedAnchorTag = UpdatedAnchorTag.replace('(http', 'http');
            commentHtml
                .getElementsByTagName('a')
                [i].setAttribute('href', UpdatedAnchorTag);
        }
        comment = Helper.trimHTML(commentHtml).innerHTML?.toString();
        comment = comment.replace(/\)<\/a>/g, '</a>)');
        if (
            (comment === null || comment === undefined || !comment) &&
            !inputs.file &&
            !inputs.fileName
        ) {
            this.notificationService.showSnackBar(
                'Please input evidence or attach a document to continue',
                true
            );
            buttonRef.loader = false;
            typeof onFlowCompleted === 'function' && onFlowCompleted(false);
            return;
        }
        //converting text to file
        if (comment !== '') {
            const blob = new Blob([comment], { type: 'text/html' });
            const fileName = commentId + '.html';
            const file = new File([blob], fileName, { type: 'text/html' });
            Helper.convertTextToBase64(file)
                .then((data) => {
                    inputs.comment = data;
                    const { preSignedUrl, key } = response;
                    const apiArgs: IHitApi = {
                        url: preSignedUrl,
                        input: data,
                        requestType: RequestType.PUT,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED
                        },
                        function: (res) => {
                            inputs.comment = key;
                            if (this.file) {
                                this.uploadDocument(
                                    buttonRef,
                                    inputs,
                                    onFlowCompleted
                                );
                            } else {
                                //to make file key and filename null and not undefined in payload
                                inputs.file = null;
                                inputs.fileName = null;
                                this.submit(buttonRef, inputs, onFlowCompleted);
                            }
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error
                            );
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetHttpService,
                        this.widgetHttpService.ngZone
                    ).hitApi();
                })
                .catch((error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    typeof onFlowCompleted === 'function' &&
                        onFlowCompleted(false);
                });
        }
    }
    uploadDocument(btnRef, inputs, onFlowCompleted?: Function) {
        if (this.file) {
            this.uploadingDocument = true;
            const apiArgs: IHitApi = {
                url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.FILE,
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (res) => {
                    const { preSignedUrl, key } = res;
                    const apiArgs: IHitApi = {
                        url: preSignedUrl,
                        input: {
                            file: this.file,
                            fileName: this.fileName
                        },
                        requestType: RequestType.PUT,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED,
                            defaultHeaders: {
                                'Content-Type': 'application/json'
                            }
                        },
                        function: (res) => {
                            inputs.file = key;
                            this.submit(btnRef, inputs, onFlowCompleted);
                            this.cdRef.detectChanges();
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error
                            );
                            btnRef.showloader = false;
                            btnRef.disable = false;
                            typeof onFlowCompleted === 'function' &&
                                onFlowCompleted(false);
                            this.uploadingDocument = false;
                            this.file = null;
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetHttpService,
                        this.widgetHttpService.ngZone
                    ).hitApi();
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.editCommentData = null;
                    this.commentId = null;
                    this.file = null;
                    this.fileName = null;
                    this.loader = false;
                    btnRef.showLoader = false;
                    btnRef.disable = false;
                    typeof onFlowCompleted === 'function' &&
                        onFlowCompleted(false);
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace(
                '{attribute-id}',
                this.frameworkData['id']
            );
            apiArgs.input = {
                ...apiArgs.input,
                framework: [this.frameworkData['framework'].id]
            };
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        } else {
            this.uploadingDocument = false;
            this.notificationService.showSnackBar(
                'Please input evidence or attach a document to continue',
                true
            );
            btnRef.disable = false;
            btnRef.showLoader = false;
            if(!this.frameworkData['disabled']){
                this.attachDocButton.disable = false;
            }
            typeof onFlowCompleted === 'function' && onFlowCompleted(false);
            return;
        }
    }
    getFrameworkPath() {
        const apiArgs: IHitApi = {
            url: ApiUrls.FRAMEWORK_PATH_API,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.globalDataService.routingData.next(data);
            },
            errorFunction: (error) => {}
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{attribute-id}',
            this.frameworkData['id']
        );
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    generateInput(commentId?) {
        const input = {
            comment: this.uploadEvidenceFormGroup.get('evidence').value ?? '',
            file: this.file,
            fileName: this.fileName,
            public: this.isPublic
        };
        if (commentId) {
            input['parentCommentId'] = commentId;
        }
        return input;
    }
    delete(commentId, editable, commentType?) {
        if (!editable) {
            return;
        }
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }

        if (this.fileIconDisable.get(commentId)) {
            return;
        }
        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol, formGroup: FormGroup) => {
                const apiArgs: IHitApi = {
                    url: ApiUrls.DELETE_COMMENT_API,
                    input: {},
                    requestType: RequestType.DELETE,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    function: () => {
                        this.confirmationModalId = modalId;
                        this.getCommentUrls(this.frameworkData['framework'].id);
                        this.notificationService.showSnackBar(
                            `Comment deleted Successfully.`
                        );
                        this.cdRef.detectChanges();
                        this.modalService.closeModal(null, modalId);
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.notificationService,
                            error
                        );
                        this.modalService.closeModal(null, modalId);
                    }
                };
                apiArgs.intactUrl = apiArgs.url;
                apiArgs.url = apiArgs.url.replace('{commentId}', commentId);

                new HitApi(
                    apiArgs,
                    this.widgetHttpService,
                    this.widgetHttpService.ngZone
                ).hitApi();
            },
            modalName: 'Delete Comment',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage:
                commentType === CommentType.COMMENT
                    ? `Deleting this message will delete all the child comments. Click delete to proceed ahead.`
                    : `Are you sure you want to delete comment ?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 30,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    // can be used to update status or score
    changeStatusOfFramework(
        action,
        buttonRef,
        newStatus?,
        scoreUpdateOnly?: boolean
    ) {
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.statusLoader.get(this.frameworkStatus.READY_FOR_ASSESSMENT) ||
            this.statusLoader.get(this.frameworkStatus.MARKED_FOR_REVIEW) ||
            this.statusLoader.get(this.frameworkStatus.REJECTED) ||
            this.statusLoader.get(this.frameworkStatus.APPROVED)
        ) {
            return;
        }
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        this.statusLoader.set(action, true);

        const sendStatus = (score?: number) => {
            const input = {
                frameworkIds: [this.frameworkData['framework']['id']],
                ...(typeof score === 'number' ? { score } : {})
            };
            const apiArgs: IHitApi = {
                url: scoreUpdateOnly
                    ? ApiUrls.FRAMEWORK_CHANGE_SCORE_API
                    : ApiUrls.FRAMEWORK_STATUS_API_V2,
                input: input,
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: () => {
                    buttonRef.loader = false;
                    this.notificationService.showSnackBar(
                        scoreUpdateOnly
                            ? 'Control Point Score Changed.'
                            : newStatus
                            ? `Response has been submitted successfully`
                            : `Control Point Status Changed.`
                    );
                    this.statusLoader.set(action, false);
                    this.getStatus(this.frameworkData['framework'].id);
                    this.menuTrigger?.closeMenu();
                    this.cdRef.detectChanges();
                },
                errorFunction: (error) => {
                    buttonRef.loader = false;
                    this.statusLoader.set(action, false);
                    Helper.showErrorMessage(this.notificationService, error);
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace('{action-enum}', action);
            if (newStatus) {
                apiArgs.input['assessmentSubAction'] = newStatus;
            }
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        };

        const isScoreFlowForHpeMaturityAudit =
            this.assessmentCacheService.assessmentAuditType ===
                AssessmentAuditTypes.MATURITY &&
            (action === FrameworkStatus.APPROVED ||
                action === FrameworkStatus.REJECTED);
        const isScoreFlowForScoringAudit =
            (this.isCSPScoring || this.isMsSupportScoring) && action === 'change-score';

        if (isScoreFlowForHpeMaturityAudit || isScoreFlowForScoringAudit) {
            this.getScoreFromScoringModal(
                (modalId?: Symbol, formGroup?: FormGroup) => {
                    const scoreValue = formGroup?.get('score')?.value;
                    sendStatus(scoreValue && parseInt(scoreValue));
                    this.modalService.closeModal(null, modalId);
                    this.cdRef.detectChanges();
                },
                () => {
                    buttonRef.loader = false;
                    this.statusLoader.set(action, false);
                    this.cdRef.detectChanges();
                }
            );
        } else {
            sendStatus();
        }
    }
    toggleReplySection(commentId) {
        if (this.showReply.has(commentId) && this.showReply.get(commentId)) {
            this.showReply.set(commentId, false);
        } else {
            this.showReply.set(commentId, true);
        }
        this.cdRef.detectChanges();
    }
    editComment(commentData, commentId, reply, editable) {
        this.assessorButtonGenInput.forEach((button) => {
            button.disable = true;
        });
        this.assesseeButtonGenInput.forEach((button) => {
            button.disable = true;
        });
        this.commentType = commentData.ispublic
            ? this.eCommentType.PUBLIC
            : this.eCommentType.PRIVATE;
        if (!editable) {
            return;
        }
        if (
            this.assesseeSubmitted &&
            !this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (
            this.assessorSubmitted &&
            this.userDataCacheService.isAssessorView
        ) {
            return;
        }
        if (this.fileIconDisable.get(commentId)) {
            return;
        }
        if (commentData['file']) {
            this.disableCross = false;
        }
        this.editCommentData = commentData;
        this.visibleEditorId = commentId;
        this.toggle(commentData['comment']);
        if (commentId && reply) {
            this.commentId = commentId;
        }
        this.cdRef.detectChanges();
    }

    private getScoreFromScoringModal(onSuccess: Function, onCancel: Function) {
        let scoringAssessmentModalData:
            | IScoringAssessmentModal
            | IScoringAssessmentDiscreetOptionsModal = {
            descriptionText: `Please enter the score${
                this.isCSPScoring && `(0-${this.maxScore})`
            } for the selected control point(s)`,
            buttonText: 'Submit',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            fontSize: 'fs400',
        };
        if (this.isCSPScoring) {
            scoringAssessmentModalData.valuesAllowedAsScore = Array.from(
                { length: this.maxScore + 1 },
                (el, i) => `${i}`
            );
            scoringAssessmentModalData.formGenInputs = {
                formName: 'Score change modal',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Score',
                        placeholder: 'Enter score',
                        name: 'score',
                        fieldType: FilterType.TEXT,
                        required: true,
                        appearance: 'legacy',
                        showLabel: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Score is required.',
                            },
                            {
                                validator: CustomValidators.regexValidator(
                                    `^[${scoringAssessmentModalData.valuesAllowedAsScore.join(
                                        ''
                                    )}]$`
                                ),
                                errorMessage: 'Score should be in valid range',
                            },
                        ],
                    },
                ],
            };
        }
        if (this.isMsSupportScoring) {
            scoringAssessmentModalData =
                scoringAssessmentModalData as IScoringAssessmentDiscreetOptionsModal;
            scoringAssessmentModalData.descriptionText = `Please select the new score for selected control point`;
            scoringAssessmentModalData.valuesAllowedAsScore =
                this.scoringDiscreetOptions;
        }
        const modalData: IModalData = {
            modalName: 'Scores',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: this.isMsSupportScoring
                            ? ScoringAssessmentDiscreetOptionsModalComponent
                            : ScoringAssessmentModalComponent,
                        payload: {
                            data: {
                                function: onSuccess,
                                params: scoringAssessmentModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ],
            closeCallback: onCancel
        };
        if (this.isCSPScoring) {
            modalData.modalHeightVh = 37;
            modalData.modalWidthVw = 50;
        } else {
            modalData.modalAutoHeight = true;
            modalData.modalWidthVw = 50;
        }
        this.modalService.openModal(modalData);
    }

    get isCSPScoring() {
        return (
            this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.CSP_SCORING
        );
    }

    get isMsSupportScoring() {
        return (
            this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.MS_SUPPORT
        );
    }
}

enum Tab {
    TECHNICAL_DESCRIPTION = 'Description',
    UPLOAD_EVIDENCE = 'Upload Evidence',
    TECHNICAL_ASSESSMENT = 'Questionnaire Form'
}
enum CommentType {
    COMMENT = 'Comment',
    REPLY = 'Reply'
}
