import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { GlobalConfiguration } from './../../../../../core/classes/GlobalConfiguration';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { SupportedFormat } from './../../../../enums/SupportedFormat';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { ConfigCacheService } from './../../../../services/cache/config-cache/config-cache.service';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-create-report-first-step',
    templateUrl: './create-report-first-step.component.html',
    styleUrls: ['./create-report-first-step.component.sass']
})
export class CreateReportFirstStepComponent implements OnInit {
    reportNameFormGenInput: IFormGeneratorInput = null;
    reportNameFormGroup: FormGroup;
    widgetsSelected: string[] = [];
    widgetsSelectedMap: Map<string, string> = new Map();
    noWidgetsSelected = false;
    SupportedFormat = SupportedFormat;
    GlobalConfiguration = GlobalConfiguration;
    ApiUrls = ApiUrls;
    edit = false;
    stepData = null;
    buttonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.goToStepTwo.bind(this)
    };
    isReadAccess: boolean = false;

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        public configCacheService: ConfigCacheService,
        private userDataCacheService: UserDataCacheService
    ) {
        this.getAddedByUser = this.getAddedByUser.bind(this);
    }

    ngOnInit(): void {
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['cartWidgets']
        ) {
            this.widgetsSelected = this.modalInputData.data['cartWidgets'];
        }
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['edit']
        ) {
            this.edit = true;
            this.stepData = this.modalInputData.data['stepData'];
            this.widgetsSelected = this.stepData['widgetIds'];
        }

        let reportName = this.edit ? this.stepData['reportName'] : null;
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
            this.widgetsSelected = stepData['widgetsSelected'];
            this.widgetsSelectedMap = stepData['widgetsSelectedMap'];
            reportName = stepData['reportName'];
        }
        this.reportNameFormGenInput = {
            formName: 'ReportName',
            state: this.edit ? FormState.IDLE : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'reportName',
                    fieldType: FilterType.TEXT,
                    label: 'Report Name',
                    placeholder: 'Enter Report Name',
                    required: true,
                    value: reportName ? reportName : '',
                    disabled: this.edit,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Report name is required'
                        },
                        {
                            validator: CustomValidators.noSpecialCharacters,
                            errorMessage:
                                'Report name can not contain special characters'
                        }
                    ]
                }
            ]
        };
        this.isReadOrWriteAccess(this.modalInputData.data?.stepData);
    }
    isReadOrWriteAccess(carData) {
        const currentUser = this.userDataCacheService.emailId;
        if (currentUser === carData?.createdBy?.email) {
            return;
        } else {
            carData?.userReportWithAccessControl?.map((user) => {
                const userEmail = user?.userInfo?.email;
                if (
                    currentUser === userEmail &&
                    user?.reportAccessControlFlag === 'READ'
                ) {
                    this.isReadAccess = true;
                    return;
                }
            });
        }
    }
    public handleKeyDown(event: KeyboardEvent) {
        if (this.isReadAccess) {
            event.preventDefault();
        }
    }
    public getAddedByUser(widgetId) {
        const currentUser = this.userDataCacheService.emailId;
        if (
            currentUser === this.modalInputData.data?.stepData?.createdBy?.email
        ) {
            return;
        }
        const userNameWithWidgetsIds =
            this.modalInputData.data?.stepData?.userNameWithWidgetsIds;
        if (userNameWithWidgetsIds) {
            const usersNamesList = Object.keys(userNameWithWidgetsIds);
            for (let i = 0; i < usersNamesList.length; i++) {
                const isWidgetFoundInList =
                    userNameWithWidgetsIds[usersNamesList[i]].findIndex(
                        (id) => {
                            return widgetId === id;
                        }
                    ) !== -1;
                if (isWidgetFoundInList) {
                    return `By ${usersNamesList[i]}`;
                }
            }
        }
        return '';
    }

    goToStepTwo() {
        Helper.markAllFieldAsTouched(this.reportNameFormGroup);
        if (!this.reportNameFormGroup || this.reportNameFormGroup.invalid) {
            return;
        }
        if (!this.widgetsSelected || !this.widgetsSelected.length) {
            this.noWidgetsSelected = true;
            return;
        }

        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, {
                reportName: this.reportNameFormGroup.get('reportName').value,
                widgetsSelected: this.widgetsSelected,
                widgetsSelectedMap: this.widgetsSelectedMap
            });
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
        if (this.edit) {
            if (this.modalInputData.data?.stepData?.userNameWithWidgetsIds) {
                this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .set(
                        3,
                        this.modalInputData.data?.stepData?.userNameWithWidgetsIds
                            ? this.modalInputData.data?.stepData
                                  ?.userNameWithWidgetsIds
                            : {}
                    );
            }
        }
    }
}
