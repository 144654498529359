<div
    class="technicalSection"
    [style.height]="
        !assessmentCacheService.assessmentView ? 'calc(100% - 40px)' : '93%'
    "
>
    <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        mat-align-tabs="center"
        [disableRipple]="true"
        [selectedIndex]="tabIndex[selectedTab]"
        (selectedTabChange)="tabChanged($event.tab.textLabel)"
    >
        <mat-tab [label]="Tab.TECHNICAL_DESCRIPTION">
            <div class="tab-content technical-descriprion">
                <section class="tw-pb-1" *ngIf="summaryFormGenInputs">
                    <div class="section-heading">
                        <div class="tittle">Summary</div>
                    </div>
                    <div class="section-content">
                        <app-form-generator
                            *ngIf="summaryFormGenInputs"
                            [formGeneratorInput]="summaryFormGenInputs"
                            (formGroupRef)="summaryFormGroup = $event"
                        ></app-form-generator>
                    </div>
                </section>
                <section class="tw-pb-1" *ngIf="requiredEvidenceFormGenInputs">
                    <div class="section-heading">
                        <div class="tittle tw-pt-4">Required Evidence</div>
                    </div>
                    <div class="section-content">
                        <app-form-generator
                            *ngIf="requiredEvidenceFormGenInputs"
                            [formGeneratorInput]="requiredEvidenceFormGenInputs"
                            (formGroupRef)="requiredEvidenceFormGroup = $event"
                        ></app-form-generator>
                    </div>
                </section>
                <div
                    class="overlay"
                    *ngIf="
                        !summaryFormGenInputs && !requiredEvidenceFormGenInputs
                    "
                >
                    <div class="overlay-content">
                        <div
                            appIcon
                            [data]="spinnerLoader"
                            class="overlay-loader"
                        ></div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab
            [disabled]="assessmentCacheService.assessmentView"
            [label]="Tab.UPLOAD_EVIDENCE"
        >
        <app-assessment-info-bar *ngIf="infoMessage" 
            [infoMessage]="infoMessage"></app-assessment-info-bar>
            <section
                *ngIf="
                    uploadEvidenceFormGenInputs &&
                    selectedTab === 'Upload Evidence'
                "
            >
                <div class="section-content">
                    <div
                        class="overlay"
                        *ngIf="commentsLoaded === 'commentsNotLoaded'"
                    >
                        <div class="overlay-content">
                            <div
                                appIcon
                                [data]="spinnerLoader"
                                class="overlay-loader"
                            ></div>
                        </div>
                    </div>
                    <div
                        *ngIf="comments && comments.length > 0"
                        class="comment-section"
                    >
                        <div
                            class="comments"
                            *ngFor="
                                let comment of frameworkData['framework'][
                                    'comments'
                                ];
                                first as isFirst
                            "
                        >
                            <div class="user-action">
                                <span
                                    [class.positive]="
                                        comment['label'] === 'Assessor'
                                    "
                                    [class.neutral]="
                                        comment['label'] === 'Assesse'
                                    "
                                    class="userName"
                                >
                                    {{ comment["userName"] }}
                                    ({{
                                        comment["label"] === "Assesse"
                                            ? "Assessee"
                                            : comment["label"]
                                    }})
                                </span>
                                <div class="actions">
                                    <div
                                        [class.disabled]="
                                            (this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false) || !comment['editable']
                                        "
                                        [class.decrease-opacity]="
                                            (this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false) || !comment['editable']
                                        "
                                        class="action-icon tw-text-accentColor"
                                        [matTooltip]="'Edit Comment'"
                                        (click)="
                                            openedEditor = 'comment';
                                            visibleEditorId = null;
                                            editComment(
                                                comment,
                                                comment['commentId'],
                                                false,
                                                comment['editable']
                                            )
                                        "
                                    >
                                        <i class="fas fa-edit"> </i>
                                    </div>
                                    <div
                                        class="action-icon tw-text-negative"
                                        [class.disabled]="
                                            (this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false) || !comment['editable']
                                        "
                                        [class.decrease-opacity]="
                                            (this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false) || !comment['editable']
                                        "
                                        (click)="
                                            delete(
                                                comment['commentId'],
                                                comment['editable'],
                                                CommentType.COMMENT
                                            )
                                        "
                                        [matTooltip]="'Delete Comment'"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="comment['file']">
                                <div
                                    class="tw-h-7 actions tw-items-center tw-justify-between tw-w-2/4 tw-bg-background-color"
                                >
                                    <div
                                        class="tw-text-default tw-truncate"
                                        [style.max-width]="160"
                                    >
                                        {{ comment["fileName"] }}
                                    </div>
                                    <div
                                        class="action-icon file-icon"
                                        appIcon
                                        [data]="downloadIcon"
                                        [matTooltip]="'Download File'"
                                        (click)="
                                            download(
                                                comment['file'],
                                                comment['commentId']
                                            )
                                        "
                                    ></div>
                                </div>
                            </ng-container>
                            <div class="comment">
                                <ng-container *ngIf="comment['comment']">
                                    <div
                                        class="content-html"
                                        [innerHTML]="
                                            comment['comment']
                                                | safeRender
                                                    : SanitizeTypes.HTML
                                        "
                                    ></div>
                                </ng-container>
                                <div class="date-action">
                                    <span class="date">
                                        {{ comment["date"] }}
                                    </span>
                                    <span class="date">
                                        Remark View:
                                        {{
                                            comment["ispublic"] === true
                                                ? "Public"
                                                : "Private"
                                        }}
                                    </span>
                                    <span
                                        [class.disabled]="
                                            this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false
                                        "
                                        [class.decrease-opacity]="
                                            this.assesseeSubmitted &&
                                            !this.userDataCacheService
                                                .isAssessorView
                                                ? true
                                                : this.assessorSubmitted &&
                                                  this.userDataCacheService
                                                      .isAssessorView
                                                ? true
                                                : false
                                        "
                                        class="tw-px-2 reply"
                                        (click)="
                                            openedEditor = 'comment';
                                            visibleEditorId = null;
                                            addReplyToComment(
                                                comment['commentId'],
                                                comment['commentId']
                                            )
                                        "
                                    >
                                        Reply
                                    </span>
                                </div>

                                <!-- Main comment editor -->
                                <ng-container
                                    *ngIf="
                                        (openedEditor === 'comment' ||
                                            !comments) &&
                                        visibleEditorId === comment['commentId']
                                    "
                                >
                                    <div class="editor tw-relative">
                                        <ng-template
                                            [ngTemplateOutlet]="editor"
                                        ></ng-template>
                                    </div>
                                </ng-container>

                                <!-- Comment Replies -->
                                <div
                                    *ngFor="let reply of comment['comments']"
                                    class="comment-reply"
                                >
                                    <div
                                        [class.neutral]="
                                            reply['label'] === 'Assesse'
                                        "
                                        [class.positive]="
                                            reply['label'] === 'Assessor'
                                        "
                                        class="userName tw-flex"
                                    >
                                        <span class="tw-flex-1">
                                            {{ reply["userName"] }}
                                            ({{
                                                reply["label"] === "Assesse"
                                                    ? "Assessee"
                                                    : reply["label"]
                                            }})
                                        </span>
                                        <div class="actions">
                                            <div
                                                class="action-icon tw-text-accentColor"
                                                [class.disabled]="
                                                    (this.assesseeSubmitted &&
                                                        (!this
                                                            .userDataCacheService
                                                            .isAssessorView
                                                            ? true
                                                            : this
                                                                  .assessorSubmitted &&
                                                              this
                                                                  .userDataCacheService
                                                                  .isAssessorView
                                                            ? true
                                                            : false)) ||
                                                    !reply['editable']
                                                "
                                                [class.decrease-opacity]="
                                                    (this.assesseeSubmitted &&
                                                        (!this
                                                            .userDataCacheService
                                                            .isAssessorView
                                                            ? true
                                                            : this
                                                                  .assessorSubmitted &&
                                                              this
                                                                  .userDataCacheService
                                                                  .isAssessorView
                                                            ? true
                                                            : false)) ||
                                                    !reply['editable']
                                                "
                                                [matTooltip]="'Edit Comment'"
                                                (click)="
                                                    openedEditor = 'reply';
                                                    visibleEditorId = null;
                                                    editComment(
                                                        reply,
                                                        reply['commentId'],
                                                        true,
                                                        reply['editable']
                                                    )
                                                "
                                            >
                                                <i class="fas fa-edit"> </i>
                                            </div>
                                            <div
                                                [class.disabled]="
                                                    this.assesseeSubmitted &&
                                                    !this.userDataCacheService
                                                        .isAssessorView
                                                        ? true
                                                        : this
                                                              .assessorSubmitted &&
                                                          this
                                                              .userDataCacheService
                                                              .isAssessorView
                                                        ? true
                                                        : false ||
                                                          !reply['editable']
                                                "
                                                [class.decrease-opacity]="
                                                    this.assesseeSubmitted &&
                                                    !this.userDataCacheService
                                                        .isAssessorView
                                                        ? true
                                                        : this
                                                              .assessorSubmitted &&
                                                          this
                                                              .userDataCacheService
                                                              .isAssessorView
                                                        ? true
                                                        : false ||
                                                          !reply['editable']
                                                "
                                                class="action-icon tw-text-negative"
                                                [matTooltip]="'Delete Comment'"
                                                (click)="
                                                    delete(
                                                        reply['commentId'],
                                                        reply['editable'],
                                                        CommentType
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="reply['file']">
                                        <div
                                            class="tw-h-7 actions tw-items-center tw-justify-between tw-w-2/4 tw-bg-background-color"
                                        >
                                            <div
                                                class="tw-text-default tw-truncate"
                                                [style.max-width]="160"
                                            >
                                                {{ reply["fileName"] }}
                                            </div>
                                            <div
                                                class="action-icon file-icon"
                                                appIcon
                                                [data]="downloadIcon"
                                                [matTooltip]="'Download File'"
                                                (click)="
                                                    download(
                                                        reply['file'],
                                                        reply['commentId']
                                                    )
                                                "
                                            ></div>
                                        </div>
                                    </ng-container>
                                    <div class="comment">
                                        <ng-container *ngIf="reply['comment']">
                                            <div
                                                class="content-html"
                                                [innerHTML]="
                                                    reply['comment']
                                                        | safeRender
                                                            : SanitizeTypes.HTML
                                                "
                                            ></div>
                                        </ng-container>
                                        <div class="date-action">
                                            <div class="tw-flex tw-flex-1">
                                                <span class="date tw-flex-none">
                                                    {{ reply["date"] }}
                                                </span>
                                                <span class="date">
                                                    Remark View:
                                                    {{
                                                        reply["ispublic"] ===
                                                        true
                                                            ? "Public"
                                                            : "Private"
                                                    }}
                                                </span>
                                                <span
                                                    [class.disabled]="
                                                        this
                                                            .assesseeSubmitted &&
                                                        !this
                                                            .userDataCacheService
                                                            .isAssessorView
                                                            ? true
                                                            : this
                                                                  .assessorSubmitted &&
                                                              this
                                                                  .userDataCacheService
                                                                  .isAssessorView
                                                            ? true
                                                            : false
                                                    "
                                                    [class.decrease-opacity]="
                                                        this
                                                            .assesseeSubmitted &&
                                                        !this
                                                            .userDataCacheService
                                                            .isAssessorView
                                                            ? true
                                                            : this
                                                                  .assessorSubmitted &&
                                                              this
                                                                  .userDataCacheService
                                                                  .isAssessorView
                                                            ? true
                                                            : false
                                                    "
                                                    class="tw-px-2 reply"
                                                    [ngStyle]="
                                                        assesseeSubmitted &&
                                                        !userDataCacheService.isAssessorView
                                                            ? {
                                                                  'pointer-events':
                                                                      'none'
                                                              }
                                                            : ''
                                                    "
                                                    (click)="
                                                        openedEditor = 'reply';
                                                        visibleEditorId = null;
                                                        addReplyToComment(
                                                            comment[
                                                                'commentId'
                                                            ],
                                                            reply['commentId']
                                                        )
                                                    "
                                                >
                                                    Reply
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            (openedEditor === 'reply' ||
                                                !comments) &&
                                            visibleEditorId ===
                                                reply['commentId']
                                        "
                                    >
                                        <div class="tw-relative editor">
                                            <ng-template
                                                [ngTemplateOutlet]="editor"
                                            ></ng-template>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="
                            commentsLoaded === 'noComments' ||
                            openedEditor === 'addEvidence'
                        "
                    >
                        <div class="editor tw-relative">
                            <ng-template
                                [ngTemplateOutlet]="editor"
                            ></ng-template>
                        </div>
                    </ng-container>
                </div>
            </section>
            <div
                [class.disabled]="
                    this.assesseeSubmitted &&
                    !this.userDataCacheService.isAssessorView
                        ? true
                        : this.assessorSubmitted &&
                          this.userDataCacheService.isAssessorView
                        ? true
                        : false
                "
                [class.decrease-opacity]="
                    this.assesseeSubmitted &&
                    !this.userDataCacheService.isAssessorView
                        ? true
                        : this.assessorSubmitted &&
                          this.userDataCacheService.isAssessorView
                        ? true
                        : false
                "
                (click)="openedEditor = 'addEvidence'; toggle()"
                *ngIf="
                    openedEditor !== 'addEvidence' &&
                    !this.userDataCacheService.isAssessorView &&
                    comments &&
                    comments.length
                "
                class="addEvidence"
            >
                <span>Add Evidence</span>
            </div>
        </mat-tab>
        <mat-tab
            [label]="Tab.TECHNICAL_ASSESSMENT"
            [disabled]="!widgetIds || !widgetIds.length"
            [matTooltip]="
                !widgetIds || !widgetIds.length
                    ? 'Not applicable for this control point'
                    : ''
            "
        >
            <ng-template mat-tab-label>
                <label
                    [class.disabled]="
                        !widgetIds || !widgetIds.length ? true : false
                    "
                    [ngStyle]="
                        !widgetIds || !widgetIds.length
                            ? { color: 'var(--grayTextColor)' }
                            : ''
                    "
                    [matTooltip]="
                        !widgetIds || !widgetIds.length
                            ? 'Not applicable for this control point'
                            : ''
                    "
                    >{{ Tab.TECHNICAL_ASSESSMENT }}</label
                >
            </ng-template>
            <div *ngIf="paginationData.size > 1" class="pages">
                <button
                    [style.visibility]="
                        currentPage === 1 ? 'hidden' : 'visible'
                    "
                    class="clickable back-page"
                    (click)="changePage(currentPage - 1)"
                >
                    <i class="fas fa-chevron-left"></i>
                </button>
                <div class="current-page">
                    <input
                        #field
                        type="number"
                        [value]="currentPage"
                        (keyup.enter)="
                            changePage($event.target.value, $event.target)
                        "
                        [min]="1"
                        [max]="paginationData.size"
                    />
                    <div class="separator">/</div>
                    <span class="totalPages">{{ paginationData.size }}</span>
                </div>
                <button
                    [style.visibility]="
                        currentPage === paginationData.size
                            ? 'hidden'
                            : 'visible'
                    "
                    class="clickable next-page"
                    (click)="changePage(currentPage + 1)"
                >
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
            <ng-container *ngIf="!loadingData.value">
                <ng-container
                    *ngFor="
                        let widgetId of widgetIds;
                        trackBy: widgetIdentify;
                        index as i
                    "
                >
                    <ng-container *ngIf="widgetData.has(widgetId)">
                        <app-widget-generator
                            [data]="widgetData.get(widgetId)"
                            [widgetIndex]="i"
                        ></app-widget-generator>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div class="overlay" *ngIf="loadingData.value">
                <div class="overlay-content">
                    <div
                        appIcon
                        [data]="spinnerLoader"
                        class="overlay-loader"
                    ></div>
                </div>
            </div>
            <div class="no-widget-text" *ngIf="!widgetIds || !widgetIds.length">
                <span>No Widget Available</span>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="btn-container">
    <div *ngIf="!userDataCacheService.isAssessorView" class="controller">
        <app-multi-button-generator
            [buttonGenInputs]="assesseeButtonGenInput"
            [options]="assesseeButtonOption"
        >
        </app-multi-button-generator>
    </div>
    <div *ngIf="userDataCacheService.isAssessorView" class="controller">
        <app-multi-button-generator
            [buttonGenInputs]="assessorButtonGenInput"
            [options]="assessorButtonOption"
        >
        </app-multi-button-generator>
        <mat-menu #metMenu="matMenu">
            <div class="metMenu">
                <app-multi-button-generator
                    [buttonGenInputs]="metButtonInputs"
                ></app-multi-button-generator>
            </div>
        </mat-menu>
        <button [matMenuTriggerFor]="metMenu" style="display: none"></button>
    </div>
</div>
<ng-template #editor>
    <div
        #commentEditor
        id="comment-editor"
        [ngClass]="openedEditor === 'addEvidence' ? 'tw-mt-5 tw-mb-0' : ''"
        class="editor"
    >
        <ng-template [ngTemplateOutlet]="downloadReport"></ng-template>
        <app-form-generator
            [formGeneratorInput]="uploadEvidenceFormGenInputs"
            (formGroupRef)="setUploadEvidenceFormGroup($event)"
        ></app-form-generator>
        <ng-container *ngIf="comments && comments.length">
            <div class="cancel-btn">
                <span
                    (click)="
                        openedEditor = null;
                        file = null;
                        fileName = null;
                        toggle();
                        editorClose(true)
                    "
                >
                    <i class="fas fa-times"></i>
                </span>
            </div>
        </ng-container>
        <div class="controls">
            <div class="tw-relative tw-left-2 tw-flex input-control">
                <input
                    #inp
                    type="file"
                    name=""
                    id="file-reader"
                    (change)="fileSelected($event)"
                />
                <div class="file-control">
                    <span class="file-name" title="{{ fileName }}">
                        {{ fileName }}
                    </span>
                    <div
                        *ngIf="fileName && !uploadingDocument && !disableCross"
                        class="cross-icon"
                        [matTooltip]="'Remove File'"
                        (click)="removeFile()"
                        appIcon
                        [data]="crossIcon"
                    ></div>
                    <span *ngIf="uploadingDocument"
                        >{{ " Uploading your document" }}
                        <span class="loading"></span
                    ></span>
                </div>
            </div>
            <div class="button-container">
                <app-button-generator
                    *ngIf="attachDocButton"
                    [buttonGenInput]="attachDocButton"
                ></app-button-generator>
                <app-button-generator
                    *ngIf="submitEvidenceButton"
                    [buttonGenInput]="submitEvidenceButton"
                ></app-button-generator>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #downloadReport>
    <div
        class="dropdown"
        [class.disabled]="
            this.assesseeSubmitted && !this.userDataCacheService.isAssessorView
                ? true
                : this.assessorSubmitted &&
                  this.userDataCacheService.isAssessorView
                ? true
                : false
        "
    >
        <app-button-generator
            [buttonGenInput]="checkButton"
        ></app-button-generator>
        <div class="menuItem">
            <mat-menu #checkMenu="matMenu">
                <mat-radio-group
                    class="tw-flex"
                    name="commentType"
                    (change)="commentType = $event.value"
                >
                    <ul class="list">
                        <li class="list-item">
                            <div class="item-private">
                                <div class="input-text">
                                    <mat-radio-button
                                        [checked]="
                                            commentType == eCommentType.PRIVATE
                                        "
                                        value="Private"
                                    ></mat-radio-button>
                                    <span class="private-text">Private</span>
                                </div>
                                <span class="private-sub-text"
                                    >Only you can view the content</span
                                >
                            </div>
                            <div class="item-public">
                                <div class="input-text">
                                    <mat-radio-button
                                        [checked]="
                                            commentType == eCommentType.PUBLIC
                                        "
                                        value="Public"
                                    ></mat-radio-button>
                                    <span class="public-text">Public</span>
                                </div>
                                <span class="public-sub-text"
                                    >Partner can view the content in both
                                    console and detailed report.</span
                                >
                            </div>
                        </li>
                    </ul>
                </mat-radio-group>
            </mat-menu>
        </div>
    </div>
</ng-template>
